/* text editor*/
.rs-picker-default .rs-picker-toggle.rs-btn {
    /* padding-top: 7px;
    padding-bottom: 7px; */
    width: 282px;
    /* padding: 50px 5px; */
    /* padding-top: 40px; */
    margin: auto 0px;
    display: inline-block;
    border-radius: 0px;
    height: 100%;
    background-color: red;
    border: 1px solid #cbcbcb;
}

/* picked date align */
.rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 67px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    padding-left: 44px;
    background-color: red;
    /* border: 1px solid red; */
}
/* calander align */
.rs-picker-toggle-caret {
    display: inline-block;
    margin-right: 240px;
    position: absolute;
    top: 8px;
    right: 12px;
    font-weight: normal;
    color: #8e8e93;
    /* border: 1px solid red; */
}

/* ok button style */
.rs-picker-toolbar-right-btn-ok { 
    text-align: center; 
    cursor: pointer;
    outline: 0 ; 
    border: none; 
    padding: 8px 12px; 
    font-size: 14px; 
    border-radius: 30px;
    color: #fff;
    background-color: #3498ff;
    width: 100px;
}

/* range style */
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    border-radius: 21px;
    display: inline-block;
    /* border: 1px solid red; */
}



.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
   position: absolute;
   top: 0px;
   left: 250px;
    /* right: -1px; */
    width: 20px;
}


.RRCDatepickerRange{
    overflow: visible;
    margin-right:  300px ;
}