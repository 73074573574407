

@media print {
  .page-break{
    display: block;
    
  }
  h2{page-break-before: always;}
  
  
}

@page {
  size: auto;
  margin: 10mm;
}




