
.uploaded-module-file-item {

    list-style: none;
    margin: 1.2em 0;
    background-color: #f55e3038;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 1.2em 1.5em;

}

.uploaded-module-file-item svg{
    color: #f55321;
}

.uploaded-module-file-item svg:first-child{
    font-size: 1.2em;
    margin-right: 0.8em;
}
.uploaded-module-file-item p {
    flex: 1;
    font-size: 0.9rem;
}
.uploaded-module-file-item .actions{
    justify-self: flex-end;
    cursor: pointer;

}

.uploaded-module-file-item .actions .fa-spinner {
    font-size: 1.2em;
}